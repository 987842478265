var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":_vm.id,"title":"Thành viên gia đình","ok-only":"","scrollable":"","size":"lg","ok-title":"Đóng","ok-variant":"secondary","hide-header-close":true,"no-close-on-backdrop":""},on:{"show":_vm.onOpen,"hidden":_vm.resetModal,"ok":_vm.resetModal}},[_c('div',{staticClass:"member-list-container"},[_c('vue-good-table',{staticClass:"pt-2",attrs:{"columns":_vm.columns,"rows":_vm.data,"select-options":{
        enabled: false,
        selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true, // disable the select info panel on top
        selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
      }},scopedSlots:_vm._u([{key:"table-row",fn:function(props){return [(props.column.field === 'address')?_c('span',[_vm._v(" "+_vm._s([props.row.address_detail, props.row.ward ? props.row.ward.full_name : '',props.row.district ? props.row.district.name : '',props.row.province ? props.row.province.name : ''].join(", "))+" ")]):(props.column.field === 'relation')?_c('span',[_vm._v(" "+_vm._s(_vm.resolveRelation(props.row.relation))+" ")]):(props.column.field === 'birthday')?_c('span',[_vm._v(" "+_vm._s(_vm.resolveBirthday(props.row.birthday))+" ")]):_c('span',[_vm._v(" "+_vm._s(props.formattedRow[props.column.field])+" ")])]}}])},[_c('div',{attrs:{"slot":"emptystate"},slot:"emptystate"},[_c('div',{staticClass:"text-center text-muted"},[_vm._v(" Không có bản ghi nào! ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }