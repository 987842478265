import { extend, localize } from 'vee-validate';
import {
  required as rule_required,
  email as rule_email,
  min as rule_min,
  confirmed as rule_confirmed,
  regex as rule_regex,
  between as rule_between,
  max_value as rule_max_value,
  min_value as rule_min_value,
  alpha as rule_alpha,
  integer as rule_integer,
  digits as rule_digits,
  alpha_dash as rule_alpha_dash,
  alpha_num as rule_alpha_num,
  length as rule_length,
} from 'vee-validate/dist/rules';
import en from 'vee-validate/dist/locale/en.json';
import vi from 'vee-validate/dist/locale/vi.json';

// eslint-disable-next-line object-curly-newline
import {
  validatorAgreement,
  validatorPositive,
  validatorRepresenter,
  validatorUrlValidator,
  validatorPassword,
  validatorCreditCard,
  validatorStartDateInvalid,
  validatorEndDateInvalid,
} from './validators';

// ////////////////////////////////////////////////////////
// General
// ////////////////////////////////////////////////////////

export const required = extend('required', {
  // eslint-disable-next-line camelcase
  ...rule_required,
  message: 'Thông tin bắt buộc',
});

export const email = extend('email', {
  // eslint-disable-next-line camelcase
  ...rule_email,
  message: 'Email không hợp lệ',
});

export const min = extend('min', {
  // eslint-disable-next-line camelcase
  ...rule_min,
  message: 'Yêu cầu tối thiểu {length} ký tự',
});

export const confirmed = extend('confirmed', {
  // eslint-disable-next-line camelcase
  ...rule_confirmed,
  message: '{_field_} không khớp',
});

export const regex = extend('regex', rule_regex);

// eslint-disable-next-line camelcase
export const between = extend('between', { ...rule_between, message: '{_field_} phải nằm trong khoảng giá trị cho phép từ {min} đến {max}' });

export const maxValue = extend('maxValue', {
  // eslint-disable-next-line camelcase
  ...rule_max_value,
  message: '{_field_} vượt quá giá trị tối đa. Giá trị tối đa cho phép là {max}',
});

export const minValue = extend('minValue', {
  // eslint-disable-next-line camelcase
  ...rule_min_value,
  message: '{_field_} phải lớn hơn hoặc bằng giá trị tối thiểu cho phép là {min}',
});

export const alpha = extend('alpha', rule_alpha);

export const integer = extend('integer', rule_integer);

export const digits = extend('digits', rule_digits);

export const alphaDash = extend('alpha-dash', rule_alpha_dash);

export const alphaNum = extend('alpha-num', rule_alpha_num);

export const length = extend('length', rule_length);

export const agreement = extend('agreement', {
  validate: validatorAgreement,
  message: 'Vui lòng chấp nhận điều khoản',
});

export const representor = extend('representor', {
  validate: validatorRepresenter,
  message: 'Yêu cầu có 1 khách thuê làm đại diện hợp đồng',
});

export const positive = extend('positive', {
  validate: validatorPositive,
  message: 'Please enter positive number!',
});

export const credit = extend('credit-card', {
  validate: validatorCreditCard,
  message: 'It is not valid credit card!',
});

export const password = extend('password', {
  validate: validatorPassword,
  message:
    '{_field_} phải chứa tối thiểu 1 ký tự chữ hoa, 1 ký tự chữ thường, 1 ký tự đặc biệt và 1 số',
});

export const url = extend('url', {
  validate: validatorUrlValidator,
  message: 'URL is invalid',
});

export const dateStartInvalid = extend('dateStartInvalid', {
  validate: validatorStartDateInvalid,
  message: 'Ngày bắt đầu phải nhỏ hơn ngày kết thúc!',
});

export const dateEndInvalid = extend('dateEndInvalid', {
  validate: validatorEndDateInvalid,
  message: 'Ngày kết thúc phải lớn hơn ngày bắt đầu!',
});

// Install English and Arabic localizations.
localize({
  en: {
    messages: en.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: '{_field_} is too short, you want to get hacked?',
      },
    },
  },
  vi: {
    messages: vi.messages,
    names: {
      email: 'Email',
      password: 'Password',
    },
    fields: {
      password: {
        min: 'كلمة السر قصيرة جداً سيتم اختراقك',
      },
    },
  },
});
