var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-form-group',{attrs:{"state":_vm.state},scopedSlots:_vm._u([(_vm.label !== null)?{key:"label",fn:function(){return [_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"text-danger"},[_vm._v(" (*) ")]):_vm._e()]},proxy:true}:null],null,true)},[_c('v-select',{attrs:{"id":"ward","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.wards,"get-option-label":function (option) { return option.name; },"disabled":_vm.district == null,"placeholder":"Chọn xã/phường"},on:{"input":_vm.wardChanged,"open":_vm.onOpen},scopedSlots:_vm._u([{key:"option",fn:function(ref){
var name = ref.name;
var full_name = ref.full_name;
return [_vm._v(" "+_vm._s(full_name ? full_name : name)+" ")]}},{key:"no-options",fn:function(ref){
var search = ref.search;
var searching = ref.searching;
var loading = ref.loading;
return [_vm._v(" Không có dữ liệu ")]}}]),model:{value:(_vm.ward),callback:function ($$v) {_vm.ward=$$v},expression:"ward"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.error))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }