import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPartners(ctx, data) {
      return useJwt.getPartners(data).then(response => response);
    },
    createPartner(ctx, data) {
      return useJwt.createPartner(data).then(response => response);
    },
    updatePartner(ctx, data) {
      return useJwt.updatePartner(data).then(response => response);
    },
    deletePartner(ctx, id) {
      return useJwt.deletePartner(id).then(response => response);
    },
  },
};
