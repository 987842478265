export const validatorPositive = value => {
  if (value >= 0) {
    return true;
  }
  return false;
};

export const validatorAgreement = value => value;

export const validatorRepresenter = value => {
  if (value && value.length > 0) {
    return value.filter(tenant => tenant.is_represent === true).length === 1;
  }
  return false;
};

export const validatorPassword = password => {
  /* eslint-disable no-useless-escape */
  const regExp = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/;
  /* eslint-enable no-useless-escape */
  const validPassword = regExp.test(password);
  return validPassword;
};

export const validatorCreditCard = creditnum => {
  /* eslint-disable no-useless-escape */
  const cRegExp = /^(?:3[47][0-9]{13})$/;
  /* eslint-enable no-useless-escape */
  const validCreditCard = cRegExp.test(creditnum);
  return validCreditCard;
};

export const validatorUrlValidator = val => {
  if (val === undefined || val === null || val.length === 0) {
    return true;
  }
  /* eslint-disable no-useless-escape */
  const re = /^(http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
  /* eslint-enable no-useless-escape */
  return re.test(val);
};

export const validatorStartDateInvalid = (val, [args]) => {
  const startDates = `${val}`.split(/[-/]/);
  const endDates = `${args}`.split(/[-/]/);
  const timeStart = new Date(startDates[2], startDates[1], startDates[0]);
  const timeEnd = new Date(endDates[2], endDates[1], endDates[0]);
  return timeStart.getTime() < timeEnd.getTime();
};

export const validatorEndDateInvalid = (val, [args]) => {
  const endDates = `${val}`.split(/[-/]/);
  const startDates = `${args}`.split(/[-/]/);
  const timeStart = new Date(startDates[2], startDates[1], startDates[0]);
  const timeEnd = new Date(endDates[2], endDates[1], endDates[0]);
  return timeStart.getTime() < timeEnd.getTime();
};
