import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import memberStoreModule from '../memberStoreModule';

export default function useMemberListModal(props) {
  const STORE_MODULE_NAME = "member";

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, memberStoreModule);
  }

  const toastification = toast();
  const isLoading = ref(false);
  const data = ref([]);

  const resetModal = () => {

  };

  const fetchMembers = () => {
    isLoading.value = true;
    store.dispatch('member/fetchMembers', props.item._id).then(response => {
      data.value = response.data;
      isLoading.value = false;
    }).catch(error => {
      toastification.showToastError(error);
      isLoading.value = false;
    });
  };

  const onOpen = () => {
    console.log(props.item);
    if (props.item) {
      fetchMembers();
    }
  };

  const resolveRelation = relation => {
    switch (relation) {
      case 'wife': return 'Vợ';
      case 'husband': return 'Chồng';
      case 'child': return 'Con';
      case 'sibling': return 'Anh em';
      case 'biological_father': return 'Bố';
      case 'biological_mother': return 'Mẹ';
      case 'father_in_law': return 'Bố vợ';
      case 'mother_in_law': return 'Mẹ vợ';
      case 'other': return 'Khác';
      default: return "";
    }
  };

  const resolveBirthday = birthday => {
    if (moment(birthday).isValid()) {
      return moment(birthday).format('DD-MM-YYYY');
    }
    return "";
  };

  return {
    data,
    onOpen,
    resetModal,
    resolveRelation,
    resolveBirthday,
  };
}
