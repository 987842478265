<template>
  <b-modal
    id="modal-patient"
    ref="refModal"
    title="Bệnh nhân"
    ok-title="Lưu"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                1. Thông tin chung
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <!-- <avatar-view align="center" /> -->
            <image-view
              v-model="itemLocal.avatar"
              label="Ảnh đại diện"
              align="center"
              :src="itemLocal.avatar"
              :width="100"
              :height="100"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="full_name"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  Họ tên <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="full_name"
                  v-model="itemLocal.full_name"
                  placeholder="Nguyen Van A"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col md="6">
            <validation-provider
              #default="validationContext"
              name="Giới tính"
              rules="required"
            >
              <select-gender
                v-model="itemLocal.gender"
                required
                :default-value="itemLocal.gender"
                label="Giới tính"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>

          <!-- Phone -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="phone"
              rules="required"
            >
              <b-form-group>
                <template v-slot:label>
                  SĐT <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="phone"
                  v-model="itemLocal.phone"
                  placeholder="024.37368315"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>
          <!-- Email -->
          <b-col cols="6">
            <validation-provider
              #default="validationContext"
              name="email"
              rules="required|email"
            >
              <b-form-group>
                <template v-slot:label>
                  Email <span class="text-danger">(*)</span>
                </template>
                <b-form-input
                  id="email"
                  v-model="itemLocal.email"
                  placeholder="patient@vntelehealth.com"
                  trim
                  :state="getValidationState(validationContext)"
                />
                <small class="text-danger">{{
                  validationContext.errors[0]
                }}</small>
              </b-form-group>
            </validation-provider>
          </b-col>

          <!-- submit -->
        </b-row>
        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                2. Cơ sở khám
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>

          <b-col cols="12">
            <facility-table />
          </b-col>
          <b-col
            cols="12"
            class="mt-2"
          >
            <validation-provider
              #default="validationContext"
              name="personal_doctor"
              rules="required"
            >
              <select-doctor
                v-model="itemLocal.personal_doctor"
                :personal-doctor="true"
                :default-value="itemLocal.personal_doctor"
                label="Chọn bác sĩ cá nhân"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>

        </b-row>

        <b-row>
          <b-col
            cols="12"
            class="my-1"
          >
            <div class="d-flex justify-space-between">
              <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
                3. Tài khoản
              </div>
            </div>
          </b-col>
        </b-row>
        <b-row>

          <b-col cols="12">
            <validation-provider
              #default="validationContext"
              name="Trạng thái tài khoản"
              rules="required"
            >

              <select-user-status
                v-model="itemLocal.status"
                :default-value="itemLocal.status"
                label="Trạng thái tài khoản"
                required
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>

          </b-col>
          <b-col
            v-if="!itemLocal._id"
            cols="6"
          >
            <!-- password -->
            <b-form-group
              label="Mật khẩu mới"
            >
              <template
                v-slot:label
              >
                Mật khẩu mới
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Mật khẩu mới"
                vid="password"
                rules="required|password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="password"
                    v-model="itemLocal.password"
                    class="form-control-merge"
                    :type="passwordFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="password"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="!itemLocal._id"
            cols="6"
          >
            <!-- confirm password -->
            <b-form-group
              label="Xác nhận mật khẩu"
            >
              <template
                v-slot:label
              >
                Xác nhận mật khẩu
                <span
                  class="text-danger"
                >
                  (*)
                </span>
              </template>
              <validation-provider
                #default="{ errors }"
                name="Xác nhận mật khẩu"
                vid="confirmPassword"
                rules="required|password|confirmed:password"
              >
                <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid' : null"
                >
                  <b-form-input
                    id="confirmPassword"
                    v-model="itemLocal.confirmPassword"
                    class="form-control-merge"
                    :type="passwordConfirmFieldType"
                    :state="errors.length > 0 ? false : null"
                    name="confirmPassword"
                    placeholder="············"
                  />
                  <b-input-group-append is-text>
                    <feather-icon
                      :icon="passwordConfirmToggleIcon"
                      class="cursor-pointer"
                      @click="togglePasswordConfirmVisibility"
                    />
                  </b-input-group-append>
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BRow,
  BCol,
  BInputGroupAppend,
  BInputGroup,
  VBTooltip,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectGender from '@/views/components/SelectGender.vue';
import SelectUserStatus from '@/views/components/SelectUserStatus.vue';
import ImageView from '@/views/components/ImageView.vue';
import { togglePasswordConfirmVisibility } from '@/layouts/mixins/forms';
import { togglePasswordVisibility } from '@core/mixins/ui/forms';
import SelectDoctor from '@/views/components/SelectDoctor.vue';
import usePatientModal from './usePatientModal';
import FacilityTable from './FacilityTable.vue';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BCol,
    BRow,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,

    SelectGender,
    SelectUserStatus,
    ImageView,
    FacilityTable,
    SelectDoctor,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [togglePasswordVisibility, togglePasswordConfirmVisibility],
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
    passwordConfirmToggleIcon() {
      return this.passwordConfirmFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon';
    },
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();

    const {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = usePatientModal(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
