import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchMembers(ctx, customerId) {
      return useJwt.getMembers(customerId).then(response => response);
    },

  },
};
