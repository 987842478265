<template>
  <b-modal
    :id="id"
    title="Thành viên gia đình"
    ok-only
    scrollable
    size="lg"
    ok-title="Đóng"
    ok-variant="secondary"
    :hide-header-close="true"
    no-close-on-backdrop
    @show="onOpen"
    @hidden="resetModal"
    @ok="resetModal"
  >
    <div class="member-list-container">

      <vue-good-table
        class="pt-2"
        :columns="columns"
        :rows="data"
        :select-options="{
          enabled: false,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
      >
        <template
          slot="table-row"
          slot-scope="props"
        >
          <!-- Column: is_represent -->
          <span v-if="props.column.field === 'address'">
            {{ [props.row.address_detail, props.row.ward ? props.row.ward.full_name : '',props.row.district ? props.row.district.name : '',props.row.province ? props.row.province.name : ''].join(", ") }}
          </span>
          <span v-else-if="props.column.field === 'relation'">
            {{ resolveRelation(props.row.relation) }}
          </span>
          <span v-else-if="props.column.field === 'birthday'">
            {{ resolveBirthday(props.row.birthday) }}
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>
        <div slot="emptystate">
          <div class="text-center text-muted">
            Không có bản ghi nào!
          </div>
        </div>
      </vue-good-table>
    </div>

  </b-modal>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table';
import useMemberListModal from './useMemberListModal';

export default {
  components: {
    BModal,
    VueGoodTable,
  },
  props: {
    item: {
      type: Object,
      default: null,
    },
    id: {
      type: String,
      default: 'modal-member-list',
    },
  },

  setup(props) {
    const {
      data,
      onOpen,
      resetModal,
      resolveRelation,
      resolveBirthday,
    } = useMemberListModal(props);

    const columns = [
      {
        label: 'Họ tên',
        field: 'full_name',
        sortable: false,
      },
      {
        label: 'Địa chỉ',
        field: 'address',
        sortable: false,
      },
      {
        label: 'Mối quan hệ',
        field: 'relation',
        sortable: false,
      },
      {
        label: 'Ngày sinh',
        field: 'birthday',
        sortable: false,
      },

    ];

    return {
      columns,
      data,
      onOpen,
      resetModal,
      resolveRelation,
      resolveBirthday,
    };
  },
};
</script>
