<template>
  <div class="d-flex align-items-center justify-content-end">
    <b-button
      v-b-tooltip.hover.v-primary
      v-b-modal="modal"
      v-b-toggle="sidebar"
      variant="primary"
      class="btn-icon ml-50"
      :title="`Thêm ${name.toLowerCase()}`"
      :to="add"
      :hidden="selectedRows.length > 0 || !showAdd"
      @click="onAddClick"
    >
      <feather-icon icon="PlusIcon" />
    </b-button>

    <b-button
      v-b-modal.import-file-modal
      v-b-tooltip.hover.v-warning
      variant="warning"
      class="btn-icon ml-50"
      title="Nhập dữ liệu"
      :to="{ name: '' }"
      :hidden="selectedRows.length > 0 || !showImport"
    >
      <feather-icon icon="UploadIcon" />
    </b-button>

    <b-button
      v-b-tooltip.hover.v-success
      variant="success"
      class="btn-icon ml-50"
      title="Xuất dữ liệu"
      :hidden="selectedRows.length > 0 || !showExport"
      @click="$emit('on-export')"
    >
      <feather-icon icon="DownloadIcon" />
    </b-button>

    <b-button
      v-b-tooltip.hover.v-danger
      :title="`Xóa ${name.toLowerCase()}`"
      variant="danger"
      class="btn-icon ml-50"
      :disabled="selectedRows.length === 0 || !enableDelete"
      :hidden="hideDelete"
      @click="onDeleteClick"
    >
      <feather-icon icon="Trash2Icon" />
    </b-button>

    <slot name="other-buttons" />

    <importFileModal
      :link-template-file="linkTemplateFile"
      @on-import-file="$emit('on-import-file', $event)"
      @download-template-file="$emit('download-template-file', $event)"
    />
  </div>
</template>

<script>
import {
  BButton, VBTooltip, BTooltip, VBModal, VBToggle,
} from 'bootstrap-vue';
import importFileModal from "@/views/components/ImportFileModal.vue";

export default {
  components: {
    BButton,
    // eslint-disable-next-line vue/no-unused-components
    BTooltip,
    // eslint-disable-next-line vue/no-unused-components
    VBTooltip,
    // eslint-disable-next-line vue/no-unused-components
    VBModal,
    // eslint-disable-next-line vue/no-unused-components
    VBToggle,
    importFileModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
    'b-toggle': VBToggle,
  },
  props: {
    name: {
      type: String,
      default: '',
      required: true,
    },
    modal: {
      type: String,
      default: null,
    },
    sidebar: {
      type: String,
      default: null,
    },
    selectedRows: {
      type: Array,
      default() {
        return [];
      },
    },
    add: {
      type: Object,
      default: null,
    },
    enableDelete: {
      type: Boolean,
      default: true,
    },
    hideDelete: {
      type: Boolean,
      default: false,
    },
    enableApprove: {
      type: Boolean,
      default: false,
    },

    enableDecline: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: true,
    },
    showImport: {
      type: Boolean,
      default: false,
    },
    showExport: {
      type: Boolean,
      default: false,
    },
    linkTemplateFile: {
      type: String,
      default: '#',
    },
  },
  setup() {},
  methods: {
    onDeleteClick() {
      this.$bvModal
        .msgBoxConfirm(
          `Bạn đang thực hiện thao tác xoá ${
            this.selectedRows.length
          } ${this.name.toLowerCase()}. Bạn có chắc chắn muốn xoá không?`,
          {
            title: `Xoá ${this.name.toLowerCase()}`,
            okTitle: 'Xoá',
            cancelTitle: 'Huỷ',
            okVariant: 'danger',
            cancelVariant: 'outline-secondary',
          },
        )
        .then(value => {
          if (value) {
            this.$emit('on-delete');
          }
        });
    },
    onAddClick() {
      this.$emit('on-add');
    },

  },
};
</script>
