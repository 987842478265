import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPatients(ctx, data) {
      return useJwt.getPatients(data).then(response => response);
    },
    createPatient(ctx, data) {
      return useJwt.createPatient(data).then(response => response);
    },
    updatePatient(ctx, data) {
      return useJwt.updatePatient(data).then(response => response);
    },
    deletePatient(ctx, id) {
      return useJwt.deletePatient(id).then(response => response);
    },
    exportPatients(ctx, data) {
      return useJwt.exportPatient(data).then(response => response);
    },
  },
};
