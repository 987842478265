import { ref, onUnmounted } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';

import userStoreModule from '@/views/profile/userStoreModule';
import partnerStoreModule from '@/views/gateway/partner/partnerStoreModule';

export default function useSetPasswordModal(props, emit, refFormObserver) {
  const STORE_MODULE_NAME = 'user';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, userStoreModule);
  }
  if (!store.hasModule('partner')) {
    store.registerModule('partner', partnerStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });

  const toastification = toast();
  const blankItem = {
    newPassword: '',
    confirmPassword: '',
  };
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(blankItem));
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    try {
      bvModalEvt.preventDefault();

      const success = await refForm.value.validate();
      if (!success) {
        return;
      }

      let data = {
        password: itemLocal.value.newPassword,
        // eslint-disable-next-line no-nested-ternary
        _id: props.patient ? props.patient._id : (props.doctor ? props.doctor._id : props.partner ? props.partner._id : ''),
      };

      if (props.partner) {
        data = {
          ...data,
          code: props.partner.code,
          email: props.partner.email,
          name: props.partner.name,
          phone: props.partner.phone,
          status: props.partner.status,
        };
      }

      let dispatchName = 'user/setUserPassword';
      if (props.partner) {
        dispatchName = 'partner/updatePartner';
      }

      store
        .dispatch(dispatchName, data)
        .then(response => { emit('on-item-updated', response.data); })
        .then(() => {
          refModal.value.toggle('#toggle-btn');
        })
        .then(() => {
          emit('refetch-data');
        })
        .then(() => {
          toastification.showToastUpdateSuccess();
        })
        .catch(error => {
          toastification.showToastError(error, refForm.value);
        });
    } catch (error) {

    }
  };

  return {
    refModal,
    refForm,
    itemLocal,
    resetItemLocal,
    resetModal,
    onSubmit,
  };
}
